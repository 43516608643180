<template>
  <component
    :is="tabType"
    :tabs="tabs"
    :current-tab="currentTab"
    :data-selector="'group-tabs'"
    @onClick="updateGroup"
  >
  </component>
</template>

<script>
import TabBar from '../navigation/TabBar'
import ChipsNavigationBar from '@/shared/components/navigation/ChipsNavigationBar.vue'
export default {
  name: 'GroupTabs',
  components: { TabBar },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    /**
     * Active group slug
     */
    activeGroup: {
      type: Object,
      required: false,
      default: undefined,
    },
    preselectFirst: {
      type: Boolean,
      default: true,
    },
    useChipsBar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tabType() {
      return this.useChipsBar ? ChipsNavigationBar : TabBar
    },
    /**
     * Map groups to tabs
     * prepend all tab
     */
    tabs() {
      let tabs = this.groups.map((g) => {
        return {
          value: g.id,
          title: g.name,
        }
      })
      return [
        {
          value: '',
          title: this.$t('common.terms.all'),
        },
        ...tabs,
      ]
    },
    currentTab() {
      if (this.activeGroup) {
        return this.activeGroup.id
      } else {
        return ''
      }
    },
  },
  mounted() {
    // check url params for pre-selected group
    if (this.$route.query.group) {
      this.updateGroup(this.$route.query.group, false, false)
    } else if (this.activeGroup) {
      this.updateQuery(this.activeGroup.id)
    } else if (this.preselectFirst && this.groups && this.groups.length > 0) {
      this.updateGroup(this.groups[0].id, true, false)
    }
  },
  methods: {
    /**
     * Pass update event parent
     * @param groupId
     * @param setQuery
     * @param emitUpdate
     */
    updateGroup(groupId, setQuery = true, emitUpdate = true) {
      let group = this.groups.find((g) => g.id === groupId)
      this.$emit('update:activeGroup', group)
      if (emitUpdate) this.$emit('change', group)
      // add query params to url
      if (setQuery) {
        this.updateQuery(groupId)
      }
    },
    /**
     * Update url query params
     * @param groupId
     */
    updateQuery(groupId) {
      let query = {
        ...this.$route.query,
        group: groupId,
      }
      if (!groupId) {
        delete query.group
      }
      this.$router.push({
        path: this.$route.path,
        query,
      })
    },
  },
}
</script>

<style scoped></style>
