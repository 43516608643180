
import Vue, { PropType } from 'vue'
import LoadingResourcePanel from '@/shared/components/resources/LoadingResourcePanel.vue'
import OrmResourcePanel from '@/shared/components/resources/OrmResourcePanel.vue'
import OrmCollection from '@/shared/components/orm/OrmCollection.vue'
import LikeButton from '@/shared/components/buttons/LikeButton.vue'
import FilterPanel from '@/shared/components/organizations/FilterPanel.vue'
import ResourceSearch from '@/shared/components/resources/ResourceSearch.vue'
import CategoryTabs from '@/shared/components/resources/CategoryTabs.vue'
import GroupTabs from '@/shared/components/resources/GroupTabs.vue'
import ShopBookingMap from '@/bookingbuddy-shop/components/maps/ShopBookingMap.vue'
import {
  Organization,
  OrganizationSettings,
} from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { ResourceCategory } from '@/shared/jsonapi-orm/bookingbuddy/ResourceCategory'
import { ResourceFilter } from '@/shared/types/ResourceTypes'
import { track } from '@/shared/utils/vueGtagHelper'
import { ResourceCollection } from '@anny.co/vue-jsonapi-orm'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { ResourceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ResourceGroup'
import { mapFields } from 'vuex-map-fields'
import { UXState } from '@/bookingbuddy-shop/store/ux'
import { Property } from '@/shared/jsonapi-orm/bookingbuddy/Property'
import ViewSwitch from '@/shared/components/utils/ViewSwitch.vue'

const uxStoreFields = {
  bowser: 'bowser',
  isEmbedded: 'isEmbedded',
}
export default Vue.extend({
  name: 'ResourcesIndex',
  components: {
    ViewSwitch,
    LoadingResourcePanel,
    OrmResourcePanel,
    OrmCollection,
    LikeButton,
    FilterPanel,
    ResourceSearch,
    CategoryTabs,
    GroupTabs,
    ShopBookingMap,
  },
  props: {
    organizationProp: {
      type: Object as PropType<Organization>,
      required: true,
    },
  },
  data() {
    return {
      organization: this.organizationProp,
      collection: new ResourceCollection<Resource>(
        Resource.api(this.$jsonApiService)
      ), // only dummy collection
      /**
       * Available sort columns for resources
       */
      resourceColumns: [
        {
          name: this.$i18n.t('pages.organizations.index.resourceColumns.name'),
          key: 'name',
        },
      ],
      /**
       * Default resource filters
       */
      resourceFilter: {
        startDate: null,
        endDate: null,
        search: '',
        categories: [],
        groups: [],
        properties: {},
      } as ResourceFilter,
      activeCategory: null as ResourceCategory | null,
      activeGroup: null as ResourceGroup | null,
    }
  },
  computed: {
    ...mapFields<typeof uxStoreFields, UXState>('ux', uxStoreFields),
    activeView(): string {
      return this.$route.query.view ? String(this.$route.query.view) : 'list'
    },
    isMapView(): boolean {
      return this.activeView === 'map'
    },
    categories(): ResourceCategory[] {
      if (this.organization?.usedCategories) {
        return this.organization.usedCategories
      }

      return []
    },
    properties(): Property[] {
      if (this.organization.properties) {
        return this.organization.properties
      }

      return []
    },
    settings(): Partial<OrganizationSettings> {
      if (this.organization) {
        return this.organization.metaSettings
      }

      return {}
    },
  },
  created() {
    let previewToken = String(this.$route.query.preview_token ?? '')
    // request resources
    this.collection?.$destruct()
    this.collection = new ResourceCollection<Resource>(
      this.organization
        .relationApi<Resource>('resources')
        .query({
          preview_token: previewToken,
        })
        .with([
          'category',
          'cover_image',
          'resource_properties.property',
          'minimal_service',
        ])
        .orderBy('name')
        .perPage(5),
      'organization-resources',
      true
    )
  },
  async mounted() {
    // set initial filters and get resources
    if (this.activeCategory) {
      await this.filterCategory(this.activeCategory)
    } else if (this.activeGroup) {
      await this.filterGroup(this.activeGroup)
    } else {
      await this.filterResources()
    }
  },
  methods: {
    async toggleViewMode(value: string) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          view: value,
        },
      })
    },
    async filterCategory(category: ResourceCategory | null) {
      this.resourceFilter.categories = category?.id ?? null
      await this.filterResources()
    },
    async filterGroup(group: ResourceGroup | null) {
      this.resourceFilter.groups = group?.id ?? null
      await this.filterResources()
    },
    /**
     * Request
     */
    async filterResources(): Promise<void> {
      await this.collection.setFilter(this.resourceFilter)

      if (this.resourceFilter.search) {
        // track search event
        track(this.$gtag, 'event', 'search', {
          search_term: this.resourceFilter.search,
        })
      }
    },
    /**
     * Generate a link for a resource
     *
     * @param {string} slug
     * @returns {*}
     */
    generateLink(slug: string): string {
      if (!slug) {
        slug = 'test'
      }
      // If we have a date selected put it into the query
      let query = { ...this.$route.query }
      if (this.resourceFilter.startDate) {
        query.start = this.resourceFilter.startDate
      }
      if (this.resourceFilter.endDate) {
        query.end = this.resourceFilter.endDate
      }
      query.from = 'organization'
      return this.localePath({
        name: 'book-resourceSlug',
        params: { resourceSlug: slug },
        query: query,
      })
    },
  },
})
