import { VueGtag } from 'vue-gtag'

export function track<T extends keyof VueGtag>(
  gtag?: VueGtag,
  call?: T,
  ...args: Parameters<VueGtag[T]>
) {
  if (!gtag || !call) {
    // console.log('vue-gtag: skip')
    return
  }
  if (window !== undefined && 'gtag' in window) {
    try {
      const method = gtag[call] as VueGtag[T]
      if (typeof method === 'function') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        method(...args)
        // console.log('vue-gtag: called ' + call)
      }
    } catch (e) {
      console.log('vue-gtag', e)
    }
  } else {
    // console.log('vue-gtag: not found')
  }
}
